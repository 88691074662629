import React from "react";

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  const pageRange = 2; // Number of pages to show before and after the current page

  // Create an array to hold the visible page numbers
  const pages = [];

  for (let i = 1; i <= totalPages; i++) {
    if (
      i <= pageRange ||
      i >= totalPages - pageRange + 1 ||
      (i >= currentPage - pageRange && i <= currentPage + pageRange)
    ) {
      pages.push(i);
    } else if (pages[pages.length - 1] !== "...") {
      pages.push("...");
    }
  }

  return (
    <nav aria-label="Page navigation example" className="mt-10">
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
        </li>

        {pages.map((page, index) => (
          <li
            className={`page-item ${page === currentPage ? "active" : ""}`}
            key={index}
          >
            {page === "..." ? (
              <span className="page-link">{page}</span>
            ) : (
              <button
                className="page-link"
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            )}
          </li>
        ))}

        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
