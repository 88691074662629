// src/components/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = !!Cookies.get("authToken"); // Check if authenticated

  return isAuthenticated ? <Element {...rest} /> : <Navigate to="/login" />;
};

export default PrivateRoute;
