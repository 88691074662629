// WaitingDialog.jsx

import React from "react";
import Lottie from "lottie-react";
import waitingAnimation from "../assets/icons/waiting.json"; // Path to your waiting animation JSON file

const WaitingDialog = () => {
  return (
    <div className="fixed inset-0 bg-gray-200 opacity-75 flex justify-center items-center z-50">
      <Lottie
        animationData={waitingAnimation}
        width={200}
        height={200}
        loop={true}
      />
    </div>
  );
};

export default WaitingDialog;
