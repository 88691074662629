import ClientModel from "../models/ClientModel";
export const base_url = "https://iacademy.host/iacademy_api";

export async function getClients(month, year, CodeColorFilter) {
  const apiUrl = `${base_url}/select_client_webflow.php?month=${month}&year=${year}&CodeColorFilter=${CodeColorFilter.replace(
    "#",
    "%23"
  )}`;

  try {
    // //console.log("filterCriteria from api", JSON.stringify(filterCriteria));
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
      //body: JSON.stringify(filterCriteria),
    });

    if (response.ok) {
      const responseData = await response.json();
      const clients = responseData.map((client) => new ClientModel(client));
      // console.log("client" + clients);
      return clients;
    } else {
      throw new Error("Erreur lors de la récupération des logements");
    }
  } catch (error) {
    //console.error(error);
    throw error;
  }
}
export async function AddHistorique(
  user_id,
  status,
  commentaire,
  date_relance
) {
  const apiUrl = `${base_url}/insert_historique.php`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        status: status,
        commentaire: commentaire,
        date_relance: date_relance,
      }),
    });

    if (response.ok) {
      const responseData = await response.json();
      // console.log("responseData.message" + responseData.message);
      return responseData.message;
    } else {
      throw new Error("Erreur lors de l'insertion dans l'historique");
    }
  } catch (error) {
    throw error;
  }
}
export async function getClientsById(month, year, iduser) {
  const apiUrl = `${base_url}/getHistoriqueById.php?month=${month}&year=${year}&user_id=${iduser}`;

  try {
    // Log the API URL to ensure it's correct
    console.log("API URL:", apiUrl);

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${token}`, // Uncomment if you are using Authorization headers
      },
    });

    // Check if the response is ok (status code 200-299)
    if (response.ok) {
      // Log the raw response to check for issues
      const responseData = await response.json();
      //  console.log("Response Data:", responseData);

      // Assuming ClientModel is a class or function to process the data
      const client = new ClientModel(responseData);
      console.log("Client Object:", client);

      return client;
    } else {
      // Log the status and response text for debugging
      const errorText = await response.text();
      console.error("Response Error Status:", response.status);
      console.error("Response Error Text:", errorText);

      throw new Error(`Error fetching data: ${response.status}`);
    }
  } catch (error) {
    // Log the error for debugging
    console.error("Fetch Error:", error);

    // Throw the error to be handled by the caller
    throw error;
  }
}

export async function setCodeCouleur(id, code_couleur) {
  const apiUrl = `${base_url}/setCodeCouleur.php`;

  try {
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        code_couleur: code_couleur,
      }),
    });

    if (response.ok) {
      const responseData = await response.json();
      // console.log("responseData.message" + responseData.message);
      return responseData.message;
    } else {
      throw new Error("Erreur lors de l'insertion dans l'historique");
    }
  } catch (error) {
    throw error;
  }
}

export async function getClientsByName(client_name) {
  const apiUrl = `${base_url}/FiltreClient.php?client_name=${client_name}`;

  try {
    // //console.log("filterCriteria from api", JSON.stringify(filterCriteria));
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
      //body: JSON.stringify(filterCriteria),
    });

    if (response.ok) {
      const responseData = await response.json();
      const clients = responseData.map((client) => new ClientModel(client));
      console.log("client" + clients);
      return clients;
    } else {
      throw new Error("Erreur lors de la récupération des logements");
    }
  } catch (error) {
    //console.error(error);
    throw error;
  }
}

// components/clientServices.jsx

export async function addClient(clientData) {
  const apiUrl = `${base_url}/newUser.php`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(clientData),
    });

    if (response.ok) {
      const responseData = await response.json();
      return responseData; // Assume the response contains a message
    } else {
      throw new Error("Erreur lors de l'envoi des données");
    }
  } catch (error) {
    throw error;
  }
}
// components/clientServices.jsx

export async function updateClient(clientData) {
  const apiUrl = `${base_url}/updateUser.php`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(clientData),
    });

    if (response.ok) {
      const responseData = await response.json();
      return responseData; // Assume the response contains a message
    } else {
      throw new Error("Erreur lors de la mise à jour des données");
    }
  } catch (error) {
    throw error;
  }
}

// src/api/getNotifications.js
export async function getNotifications() {
  const apiUrl = `${base_url}/notificationRelance.php`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const responseData = await response.json();
      return responseData; // Return the fetched data
    } else {
      throw new Error("Erreur lors de la récupération des notifications");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
