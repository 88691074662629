import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import AlertModal from "./AlertModal"; // Ajuster le chemin d'importation selon besoin
import WaitingDialog from "./WaitingDialog"; // Ajuster le chemin d'importation selon besoin
import { addClient } from "../services/ClientService"; // Ajuster le chemin d'importation selon besoin

const NewUserModal = ({ showModalUser, handleClose, responseAfterSend }) => {
  const [nomComplet, setNomComplet] = useState("");
  const [reseau, setReseau] = useState(""); // Modifié de reseauSociaux à reseau
  const [phone, setPhone] = useState(""); // Modifié de telephone à phone
  const [siteweb, setSiteweb] = useState(""); // Modifié de siteWeb à siteweb
  const [mail, setMail] = useState(""); // Modifié de email à mail
  const [niveau, setNiveau] = useState("");
  const [domaine, setDomaine] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const [codeCouleur, setCodeCouleur] = useState("#FA5450");
  const [eventName, setEventName] = useState(""); // Modifié de nomEvenement à eventName
  const [afficherPickerCouleur, setAfficherPickerCouleur] = useState(false);
  const [chargement, setChargement] = useState(false); // État pour chargement
  const [afficherAlerte, setAfficherAlerte] = useState(false); // État pour la modal d'alerte
  const [messageAlerte, setMessageAlerte] = useState(""); // Message pour la modal d'alerte
  const [disponibilite, setDisponibilite] = useState(true); // État pour la disponibilité de l'alerte

  const reinitialiserChamps = () => {
    setNomComplet("");
    setReseau("");
    setPhone("");
    setSiteweb("");
    setMail("");
    setNiveau("");
    setDomaine("");
    setCommentaire("");
    setCodeCouleur("#FA5450");
    setEventName("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Fermer le modal immédiatement
    handleClose();

    const donneesUtilisateur = {
      nomComplet,
      reseau,
      phone,
      siteweb,
      mail,
      niveau,
      domaine,
      commentaire,
      code_couleur: codeCouleur,
      Event_Name: eventName,
    };

    setChargement(true); // Démarrer le chargement
    try {
      const reponse = await addClient(donneesUtilisateur);
      setMessageAlerte(reponse.message);
      setDisponibilite(true); // Supposer un succès
      reinitialiserChamps(); // Réinitialiser les champs après succès
    } catch (erreur) {
      setMessageAlerte(erreur.message);
      setDisponibilite(false); // Erreur survenue
    } finally {
      setChargement(false); // Fin du chargement
      setAfficherAlerte(true); // Afficher la modal d'alerte
    }
    responseAfterSend(generateRandomString(28));
  };
  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters[randomIndex];
    }

    return result;
  };
  const handleColorClick = (couleur) => {
    setCodeCouleur(couleur);
    setAfficherPickerCouleur(false);
  };

  return (
    <>
      {/* Composant WaitingDialog */}
      {chargement && <WaitingDialog />}

      {/* Composant AlertModal */}
      <AlertModal
        show={afficherAlerte}
        message={messageAlerte}
        isAvailable={disponibilite}
        onClose={() => setAfficherAlerte(false)}
      />

      <Modal show={showModalUser} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un Nouveau Utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formNomComplet">
              <Form.Label>Nom Complet</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez le nom complet"
                value={nomComplet}
                onChange={(e) => setNomComplet(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formReseau">
              <Form.Label>Réseau Sociaux</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez le réseau sociaux (facultatif)"
                value={reseau}
                onChange={(e) => setReseau(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez le téléphone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formSiteweb">
              <Form.Label>Site Web</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez le site web (facultatif)"
                value={siteweb}
                onChange={(e) => setSiteweb(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formMail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Entrez l'email"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formNiveau">
              <Form.Label>Niveau</Form.Label>
              <Form.Control
                as="select"
                value={niveau}
                onChange={(e) => setNiveau(e.target.value)}
                required
              >
                <option value="">Choisir...</option>
                <option value="Novice">Novice</option>
                <option value="Débutant">Débutant</option>
                <option value="Intermédiaire">Intermédiaire</option>
                <option value="Professionnel">Professionnel</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formDomaine">
              <Form.Label>Domaine</Form.Label>
              <Form.Control
                as="select"
                value={domaine}
                onChange={(e) => setDomaine(e.target.value)}
                required
              >
                <option value="">Choisir...</option>
                <option value="Marketing">Marketing</option>
                <option value="Vidéo/Montage">Vidéo/Montage</option>
                <option value="Photographie">Photographie</option>
                <option value="Réseaux Sociaux">Réseaux Sociaux</option>
                <option value="Intelligence Artificielle">
                  Intelligence Artificielle
                </option>
                <option value="Autre">Autre</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formCommentaire">
              <Form.Label>Commentaire</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Ajouter un commentaire"
                value={commentaire}
                onChange={(e) => setCommentaire(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formCodeCouleur">
              <Form.Label>Code Couleur</Form.Label>
              <div
                className="flex items-center space-x-2 cursor-pointer text-white p-2 rounded"
                onClick={() => setAfficherPickerCouleur(!afficherPickerCouleur)}
                style={{ backgroundColor: codeCouleur }}
              >
                <span>Code Couleur: {codeCouleur}</span>
                <span className="ml-2">Changer</span>
              </div>

              {afficherPickerCouleur && (
                <div className="mt-2 flex space-x-2">
                  {["#6EBF48", "#FA5450", "#000000", "#F8BC00"].map(
                    (couleur) => (
                      <div
                        key={couleur}
                        onClick={() => handleColorClick(couleur)}
                        className={`p-2 rounded-full ${
                          codeCouleur === couleur ? "border-2 border-dark" : ""
                        }`}
                        style={{
                          backgroundColor: couleur,
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                      />
                    )
                  )}
                </div>
              )}
            </Form.Group>

            <Form.Group controlId="formEventName">
              <Form.Label>Nom de l'Événement</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez le nom de l'événement"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                required
              />
            </Form.Group>

            <div className="flex justify-between mt-4">
              <Button variant="secondary" onClick={handleClose}>
                Fermer
              </Button>
              <Button variant="primary" type="submit">
                Enregistrer
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewUserModal;
