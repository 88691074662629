import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Login } from "../services/LoginService"; // Import your LoginService
import Cookies from "js-cookie"; // Import js-cookie
import AlertModal from "../components/AlertModal"; // Import AlertModal
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import WaitingDialog from "../components/WaitingDialog"; // Import WaitingDialog component

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [showAlert, setShowAlert] = useState(false);
  const [messageDetailsPrice, setMessageDetailsPrice] = useState("");
  const [isAvailable, setIsAvailable] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Check if the token is already present
    const token = Cookies.get("authToken");
    if (token) {
      navigate("/"); // Redirect to home page if token exists
    } else {
      // Load email and password from localStorage if available
      const savedEmail = localStorage.getItem("email");
      const savedPassword = localStorage.getItem("password");
      if (savedEmail) setEmail(savedEmail);
      if (savedPassword) setPassword(savedPassword);
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Check for empty fields
    if (!email || !password) {
      setLoading(false);
      setMessageDetailsPrice("Veuillez remplir tous les champs.");
      setIsAvailable(false);
      setShowAlert(true);

      return;
    }

    try {
      // Attempt to log in
      const response = await Login(email, password);

      console.log("Login response:", response); // Debugging line

      if (response.success) {
        // Save token to cookies if available
        if (response.token) {
          Cookies.set("authToken", response.token, { expires: 90 }); // Expires in 90 days
        }

        // Save email and password to localStorage
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        setLoading(false);

        // Redirect to home page
        navigate("/");
      } else {
        setLoading(false);

        setMessageDetailsPrice(response.message || "Échec de la connexion.");
        setIsAvailable(false);
        setShowAlert(true);
      }
    } catch (error) {
      setLoading(false);

      console.error("Login error:", error); // Debugging line
      setMessageDetailsPrice(error.message || "Erreur inattendue.");
      setIsAvailable(false);
      setShowAlert(true);
    }
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Connexion</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Adresse e-mail
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="exemple@mail.com"
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Mot de passe
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"} // Toggle password visibility
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="********"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                {showPassword ? (
                  <FaEyeSlash className="text-gray-500" />
                ) : (
                  <FaEye className="text-gray-500" />
                )}
              </button>
            </div>
          </div>

          <div className="flex flex-col gap-1 items-center justify-between">
            <button
              type="submit"
              className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Se connecter
            </button>
            <Link
              to="/Forgetpwd"
              className="text-sm text-indigo-600 hover:text-indigo-700"
            >
              Modifier le mot de passe?
            </Link>
          </div>
        </form>
      </div>

      {/* AlertModal for displaying errors */}
      <AlertModal
        show={showAlert}
        message={messageDetailsPrice}
        isAvailable={isAvailable}
        onClose={() => setShowAlert(false)}
      />
      {loading && <WaitingDialog />}
    </div>
  );
};

export default LoginPage;
