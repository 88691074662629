// src/Routes/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from '../views/LoginPage'; // Adjust path as needed
import Forgetpwd from '../views/Forgetpwd'; // Adjust path as needed
import Home from '../views/Home'; // Adjust path as needed
import PrivateRoute from '../components/PrivateRoute'; // Import PrivateRoute if you have one
import NotFoundPage from '../views/404'; // Import the 404 page

const AppRouter = () => (
  <Router basename="/"> {/* Adjust the basename if necessary */}
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/Forgetpwd" element={<Forgetpwd />} />
      <Route
        path="/"
        element={<PrivateRoute element={Home} />}
      />
      <Route path="*" element={<NotFoundPage />} /> {/* 404 route */}

    </Routes>
  </Router>
);

export default AppRouter;
