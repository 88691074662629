// MonthYearPicker.jsx
import React, { useState, useEffect } from "react";
import "tailwindcss/tailwind.css"; // Ensure Tailwind CSS is imported

const MonthYearPicker = ({
  selectedMonth,
  selectedYear,
  onMonthYearChange,
}) => {
  const currentYear = new Date().getFullYear();
  const minYear = 2023; // Set minimum year

  const [month, setMonth] = useState(
    selectedMonth || new Date().getMonth() + 1
  );
  const [year, setYear] = useState(selectedYear || currentYear);

  useEffect(() => {
    // Notify parent component when month or year changes
    if (onMonthYearChange) {
      onMonthYearChange({ month, year });
    }
  }, [month, year, onMonthYearChange]);

  const handleMonthChange = (event) => {
    setMonth(parseInt(event.target.value, 10));
  };

  const handleYearChange = (event) => {
    setYear(parseInt(event.target.value, 10));
  };

  return (
    <div className="flex flex-row gap-5">
      <div className="">
        <div className="flex flex-row gap-4">
          {/* Month Selector */}
          <div className="flex flex-row gap-1 items-center justify-center">
            <p className="text-color6 font-bold font-Poppins text-lg">Mois</p>
            <select
              value={month}
              onChange={handleMonthChange}
              className="border border-gray-300 rounded-md font-bold font-Poppins text-base"
            >
              {[...Array(12)].map((_, i) => (
                <option key={i} value={i + 1}>
                  {new Date(0, i).toLocaleString("default", { month: "long" })}
                </option>
              ))}
            </select>
          </div>

          {/* Year Selector */}
          <div className="flex flex-row gap-1 items-center justify-center">
            <p className="text-color6 font-bold font-Poppins text-lg">Année</p>
            <select
              value={year}
              onChange={handleYearChange}
              className="border border-gray-300 rounded-md font-bold font-Poppins text-base"
            >
              {[...Array(currentYear - minYear + 1)].map((_, i) => (
                <option key={i} value={minYear + i}>
                  {minYear + i}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthYearPicker;
