// ClientModel.js
import paymentModel from "./paymentModel";
import historiqueModel from "./HistoriqueModel";
class ClientModel {
  constructor(data) {
    this.id = data.id;
    this.nomComplet = data.nomComplet;
    this.mail = data.mail;
    this.reseau = data.reseau;
    this.siteweb = data.siteweb;
    this.commentaire = data.iacademy_commentaire;
    this.phone = data.phone;
    this.date_ = data.date_;
    this.domaine = data.domaine;
    this.niveau = data.niveau;
    this.code_couleur = data.code_couleur;
    this.Event_Name = data.Event_Name;
    // Initialiser une liste des paiements
    this.payments = (data.payments || []).map(
      (paymentData) => new paymentModel(paymentData)
    );
    this.historiques = (data.historiques || []).map(
      (historique) => new historiqueModel(historique)
    );
  }
}

export default ClientModel;
