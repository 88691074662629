// LoginService.js
import Cookies from "js-cookie";
export const base_url = "https://iacademy.host/iacademy_api";

export async function Login(email, password) {
  const apiUrl = `${base_url}/login.php`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }), // Sending JSON data
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData.message || "Erreur de connexion");
    }

    if (responseData.success) {
      if (responseData.token) {
        Cookies.set("authToken", responseData.token, { expires: 90 }); // Save token for 90 days
      }
      return { success: true };
    } else {
      return { success: false, message: responseData.message };
    }
  } catch (error) {
    throw new Error(error.message || "Erreur inattendue");
  }
}

export async function Renewpwd(oldPassword, newPassword, email) {
  const apiUrl = `${base_url}/NewPassword.php`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, oldPassword, newPassword }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData.error);
    }
    return responseData.message; //success you should reload to
  } catch (error) {
    throw new Error(error.message || "Erreur inattendue");
  }
}
// LoginService.jsx
// LoginService.js
