// PaymentModel.jsx
class PaymentModel {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.mail = data.mail;
    this.amount = data.amount;
    this.payment_date = data.payment_date;
  }
}

export default PaymentModel;
