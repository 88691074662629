import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import WaitingDialog from "../components/WaitingDialog"; // Import WaitingDialog component
import "tailwindcss/tailwind.css"; // Ensure Tailwind CSS is imported
import {
  getClients,
  AddHistorique,
  getClientsById,
  setCodeCouleur,
  getClientsByName,
} from "../services/ClientService";
import NewUserModal from "../components/NewUserModal"; // Adjust the import path as needed
import UpdateUserModal from "../components/UpdateUserModal"; // Adjust the import path as needed

import MonthYearPicker from "../components/MonthYearPicker";
import Pagination from "../components/Pagination"; // Adjust the import path as needed
import AlertModal from "../components/AlertModal"; // Importer le composant AlertModal
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";

const Home = () => {
  const navigate = useNavigate();
  const [showModalUser, setShowModalUser] = useState(false);
  const [showUpdateModalUser, setshowUpdateModalUser] = useState(false);
  const [userDataSelected, setuserDataSelected] = useState(null);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
  const [clientsList, setClientList] = useState([]);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showContactDateModal, setShowContactDateModal] = useState(false);
  const [showColorCodeModal, setShowColorCodeModal] = useState(false);
  const [showContactPersonModal, setShowContactPersonModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [ListHistoriqueSelected, setListHistoriqueSelected] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [messageDetailsPrice, setMessageDetailsPrice] = useState("No error");
  const [isAvailable, setIsAvailable] = useState(false); // State to determine if logement is available
  const [commentaireInput, setCommentaireInput] = useState("");
  const [RelanceInput, setRelanceInput] = useState("");
  const [DateContactInput, setDateContactInput] = useState("");
  const [DateRelanceInput, setDateRelanceInput] = useState("");

  const [PersonneChargeInput, setPersonneChargeInput] = useState("");
  const [CodeColorInput, setCodeColorInput] = useState("-1");
  const [ClientNameInput, setClientNameInput] = useState("");
  const [updateResponse, setUpdateResponse] = useState(null);
  const [CodeColorFilter, setCodeColorFilter] = useState("Tous");
  const changeEtatColorCodeFilter = (Color) => {
    setCodeColorFilter(Color);
  };
  // Function to handle opening the modal
  const handleShowModalUser = () => setShowModalUser(true);
  const handleUpdateShowModalUser = (userDataSelect) => {
    setuserDataSelected(userDataSelect);
    setshowUpdateModalUser(true);
  };

  // Function to handle closing the modal
  const handleCloseModalUser = () => setShowModalUser(false);
  const handleUpdateCloseModalUser = () => setshowUpdateModalUser(false);

  const changeEtatColorCode = (Color) => {
    setCodeColorInput(Color);
  };
  const responseAfterSend = (response) => {
    // Handle the response here
    // console.log("==>", response);
    setUpdateResponse(response); //change the variable here it enough for calling useEffect
  };
  const [user_idSelected, setUser_idSelected] = useState("");
  // Redirect to login if not authenticated
  const isAuthenticated = Cookies.get("authToken") ? true : false;
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    console.log("CodeColorFilter", CodeColorFilter);
    const fetchData = async () => {
      setLoading(true);
      try {
        if (ClientNameInput === "") {
          const data = await getClients(
            selectedMonth,
            selectedYear,
            CodeColorFilter
          );
          setClientList(data);
        } else {
          const data = await getClientsByName(ClientNameInput);
          console.log("client", data);
          setClientList(data);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        // setCodeColorInput("-1");
      }
    };
    fetchData();
  }, [
    selectedMonth,
    selectedYear,
    ClientNameInput,
    updateResponse,
    CodeColorFilter,
  ]);
  const handleMonthYearChange = ({ month, year }) => {
    setSelectedMonth(month);
    setSelectedYear(year);
  };
  const getDetailsClientById = async (month, year, openModel, user_id) => {
    setLoading(true);
    try {
      const data = await getClientsById(month, year, user_id);
      console.log("res data by id", data);
      setListHistoriqueSelected(data.historiques);
      setLoading(false);
      setShowHistoryModal(openModel);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const ModifyCodeCouleur = async (codeCouleur, id) => {
    setLoading(true);
    try {
      const reponse = await setCodeCouleur(id, codeCouleur);
      if (reponse === "Update successful") setIsAvailable(true);
      else setIsAvailable(false);
      setShowAlert(true);
      setMessageDetailsPrice(reponse);
      setLoading(false);
      setCodeColorInput("-1");
      setCommentaireInput("");
      setRelanceInput("");
      setDateRelanceInput("");
      setDateContactInput("");
      setPersonneChargeInput("");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
    setCodeColorInput("-1");
    responseAfterSend(generateRandomString(28));
  };
  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters[randomIndex];
    }

    return result;
  };
  // Calculate total pages
  const totalPages = Math.ceil(clientsList.length / itemsPerPage);
  // Get current page data
  const currentData = clientsList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // Function to handle modal visibility
  const handleClose = () => {
    setShowCommentModal(false);
    setShowTaskModal(false);
    setShowHistoryModal(false);
    setShowContactDateModal(false);
    setShowColorCodeModal(false);
    setShowContactPersonModal(false);
    setCodeColorInput("-1");
  };
  const onclickCodeCouleurButton = (openModel, id) => {
    setUser_idSelected(id);
    setShowColorCodeModal(openModel);
    setCodeColorInput("#6EBF48");
  };
  const onclickHistoriqueButton = (openModel, id) => {
    setUser_idSelected(id);
    getDetailsClientById(selectedMonth, selectedYear, openModel, id);
  };
  const onclickCommentaireButton = (openModel, id) => {
    setUser_idSelected(id);
    setShowCommentModal(openModel);
  };
  const onclickPersonneChargeButton = (openModel, id) => {
    setUser_idSelected(id);
    setShowContactPersonModal(openModel);
  };
  const onclickRelanceButton = (openModel, id) => {
    setUser_idSelected(id);
    setShowTaskModal(openModel);
  };
  const onclickDateContactButton = (openModel, id) => {
    setUser_idSelected(id);
    setShowContactDateModal(openModel);
  };
  const AddAllHistoriqueContent = (user_id, event) => {
    handleClose();
    if (
      commentaireInput === "" &&
      RelanceInput === "" &&
      DateContactInput === "" &&
      PersonneChargeInput === "" &&
      CodeColorInput === "-1" &&
      DateRelanceInput === ""
    ) {
      setIsAvailable(false);
      setShowAlert(true);
      setMessageDetailsPrice("Veuillez remplir les champs vide");
    } else {
      if (CodeColorInput !== "-1") {
        //  console.log("code input", CodeColorInput, "===> user ID", user_id);
        ModifyCodeCouleur(CodeColorInput, user_id);
        setCodeColorInput("-1");
        return;
      }
      if (commentaireInput !== "") {
        AddHistoriqueContentToDataBase(
          user_id,
          "Commentaire",
          commentaireInput,
          "",
          event
        );
        return;
      }
      if (RelanceInput !== "" && DateRelanceInput !== "") {
        AddHistoriqueContentToDataBase(
          user_id,
          "Relance",
          RelanceInput,
          DateRelanceInput,
          event
        );
        return;
      }
      if (DateContactInput !== "") {
        AddHistoriqueContentToDataBase(
          user_id,
          "Date de Contact",
          DateContactInput,
          "",
          event
        );
        return;
      }
      if (PersonneChargeInput !== "") {
        AddHistoriqueContentToDataBase(
          user_id,
          "Personne en Charge",
          PersonneChargeInput,
          "",
          event
        );
        return;
      }
    }
  };
  const AddHistoriqueContentToDataBase = async (
    user_id,
    status,
    commentaire,
    date_relance,
    event
  ) => {
    event.preventDefault();
    try {
      setLoading(true);
      const reponse = await AddHistorique(
        user_id,
        status,
        commentaire,
        date_relance
      );
      if (reponse === "Insertion avec succès") setIsAvailable(true);
      else setIsAvailable(false);
      setShowAlert(true);
      setMessageDetailsPrice(reponse);
      setLoading(false);
      setCommentaireInput("");
      setRelanceInput("");
      setDateContactInput("");
      setPersonneChargeInput("");
      setDateRelanceInput("");
      setCodeColorInput("-1");
    } catch (error) {
      setIsAvailable(false);
      setShowAlert(true);
      setMessageDetailsPrice("Erreur");
      console.error("Error fetching data:", error);
      setLoading(false);
      setCommentaireInput("");
      setRelanceInput("");
      setDateContactInput("");
      setPersonneChargeInput("");
      setDateRelanceInput("");
      setCodeColorInput("-1");
    }
  };

  return (
    <div className="flex flex-col gap-2 pb-5">
      <div className="shadow-base mb-2 w-full">
        <Navbar />
      </div>
      <div className="bg-gray-100 flex flex-col w-full justify-center py-4   px-10">
        <AlertModal
          show={showAlert}
          message={messageDetailsPrice}
          isAvailable={isAvailable}
          onClose={() => setShowAlert(false)}
        />
        {/* Dashboard Header */}
        <div className="bg-gray-800 text-white p-4 rounded-xl mb-4">
          <h2 className="text-2xl">Tableau de bord CRM</h2>
        </div>
        {/* Stats Cards */}
        <div className="flex flex-col sm:flex-row lg:flex-row gap-4 mb-4">
          <div className="bg-color13 text-white p-4 rounded-xl flex-1 text-center">
            <h5 className="text-lg">
              Total Clients En{" "}
              <span className="font-bold underline">
                {selectedMonth}/{selectedYear}
              </span>
            </h5>
            <h2 className="text-4xl">{clientsList.length}</h2>
          </div>
          <div className="bg-color13 text-white p-4 rounded-xl flex-1 text-center">
            <h5 className="text-lg">
              Clients Ayant Payé En{" "}
              <span className="font-bold underline">
                {selectedMonth}/{selectedYear}
              </span>
            </h5>
            <h2 className="text-4xl">
              {
                clientsList.filter((client) => client.historiques.length > 0)
                  .length
              }
            </h2>
          </div>
          <div className="bg-color13 text-white p-4 rounded-xl flex-1 text-center">
            <h5 className="text-lg">
              Clients N'Ayant pas Payé En{" "}
              <span className="font-bold underline">
                {selectedMonth}/{selectedYear}
              </span>
            </h5>
            <h2 className="text-4xl">
              {" "}
              {
                clientsList.filter((client) => client.historiques.length === 0)
                  .length
              }
            </h2>
          </div>
        </div>
        {/* Search Bar */}
        <div className="mb-4 flex flex-col sm:flex-row gap-2">
          <input
            type="text"
            placeholder="Rechercher un client..."
            value={ClientNameInput}
            onChange={(e) => setClientNameInput(e.target.value)}
            className="flex-1 p-2 border border-gray-900  rounded-lg"
          />
        </div>
        {/* Client Table */}
        <div className="bg-white px-2 py-4 rounded-lg shadow-md">
          <div className="flex flex-col justify-start items-start mb-2">
            <div className="flex flex-row justify-between  w-full  items-center mb-2 ">
              <h3 className="text-xl font-bold mb-1">Liste des Clients</h3>
              <div
                className="px-3 py-1 w-[100px] items-start cursor-pointer
             bg-color12 text-color2 rounded-xl flex justify-center"
              >
                <p
                  className="font-Poppins font-semibold text-lg "
                  onClick={handleShowModalUser}
                >
                  Ajouter
                </p>
              </div>
            </div>

            <div className="flex flex-row justify-between items-center w-full  ">
              <MonthYearPicker
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                onMonthYearChange={handleMonthYearChange}
              />
              <div className="flex flex-row gap-1 items-center">
                <p
                  className={`border-[3px] font-Poppins  font-bold px-3 py-1 w-[100px] flex justify-center items-center text-color12 rounded-full cursor-pointer bg-white ${
                    CodeColorFilter === "Tous"
                      ? "border-color10"
                      : "border-color1"
                  }`}
                  onClick={() => changeEtatColorCodeFilter("Tous")}
                >
                  Tous
                </p>
                <p
                  className={`border-[3px] font-Poppins  font-bold px-3 py-1 w-[100px]    flex justify-center items-center text-color5 rounded-full cursor-pointer bg-color8 ${
                    CodeColorFilter === "#6EBF48"
                      ? "border-color10"
                      : "border-color5"
                  }`}
                  onClick={() => changeEtatColorCodeFilter("#6EBF48")}
                >
                  Vert
                </p>
                <p
                  className={`border-[3px] font-Poppins  font-bold  px-3 py-1 w-[100px] flex justify-center items-center text-color5 rounded-full cursor-pointer bg-color9 ${
                    CodeColorFilter === "#FA5450"
                      ? "border-color10"
                      : "border-color5"
                  }`}
                  onClick={() => changeEtatColorCodeFilter("#FA5450")}
                >
                  Rouge
                </p>
                <p
                  className={`border-[3px] font-Poppins  font-bold  px-3 py-1 w-[100px] flex justify-center items-center text-color5 rounded-full cursor-pointer bg-black ${
                    CodeColorFilter === "#000000"
                      ? "border-color10"
                      : "border-color5"
                  }`}
                  onClick={() => changeEtatColorCodeFilter("#000000")}
                >
                  Noir
                </p>
                <p
                  className={`border-[3px] font-Poppins  font-bold px-3 py-1 w-[100px] flex justify-center items-center  text-color5 rounded-full cursor-pointer bg-color3 ${
                    CodeColorFilter === "#F8BC00"
                      ? "border-color10"
                      : "border-color5"
                  }`}
                  onClick={() => changeEtatColorCodeFilter("#F8BC00")}
                >
                  Orange
                </p>
              </div>
            </div>
          </div>

          <table className="w-full table-auto border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border p-2 w-1/4">Détails</th>
                <th className="border p-2 w-1/5">Commentaire</th>
                <th className="border p-2 w-1/6">Statut</th>
                <th className="border p-2 w-1/4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item) => (
                <React.Fragment key={item.id}>
                  <tr className="border-b border-gray-300">
                    <td className="border px-2 py-1">
                      <div className="flex flex-col">
                        <div className="flex flex-row gap-2">
                          <p className="font-bold text-color6 text-xs">Nom:</p>

                          <span className="font-semibold text-color12 text-sm">
                            {item.nomComplet}
                          </span>
                        </div>
                        <div className="flex flex-row gap-2">
                          <p className="font-bold text-color6 text-xs">
                            Email:
                          </p>

                          <span className="text-color10 font-bold text-sm ">
                            {item.mail}
                          </span>
                        </div>
                        <div className="flex flex-row gap-2">
                          <p className="font-bold text-color6 text-xs">Tél:</p>

                          <span className="text-color12 text-sm">
                            {item.phone}
                          </span>
                        </div>
                        {item.reseau !== "" && (
                          <div className="flex flex-row gap-2">
                            <p className="font-bold text-color6 text-xs">
                              Réseau:
                            </p>

                            <span className="text-color12 text-sm">
                              {item.reseau}
                            </span>
                          </div>
                        )}
                        {item.siteweb !== "" && (
                          <div className="flex flex-row gap-2">
                            <p className="font-bold text-color6 text-xs">
                              Site Web:
                            </p>

                            <a
                              href={item.siteweb}
                              className="text-color12 text-sm"
                            >
                              {item.siteweb}
                            </a>
                          </div>
                        )}
                        <div className="flex flex-row gap-2">
                          <p className="font-bold text-color6 text-xs">
                            Event Name:
                          </p>

                          <span className="text-sm font-medium text-color1">
                            {item.Event_Name}
                          </span>
                        </div>
                        <div className="flex flex-row gap-2">
                          <p className="font-bold text-color6 text-xs">
                            Domaine:
                          </p>

                          <span className="text-color12 text-sm">
                            {item.domaine}
                          </span>
                        </div>
                        <div className="flex flex-row gap-2">
                          <p className="font-bold text-color6 text-xs">
                            Niveau:
                          </p>

                          <span className="text-color12 text-sm">
                            {item.niveau}
                          </span>
                        </div>

                        <span
                          className=" underline text-base  font-medium text-color12 cursor-pointer"
                          onClick={() => handleUpdateShowModalUser(item)}
                        >
                          Modifier
                        </span>
                      </div>
                    </td>
                    <td className="border px-2 py-1">
                      <span className="text-sm">{item.commentaire}</span>
                    </td>
                    <td className="border px-2 py-1">
                      <span
                        className="text-white p-2 rounded-xl mt-1"
                        style={{ backgroundColor: item.code_couleur }}
                      >
                        {item.payments.length === 0 ? "Non Payé" : "Payé"}
                      </span>
                    </td>
                    <td className="border px-2 py-1">
                      <div className="grid grid-cols-2 gap-2">
                        <Button
                          className="bg-color13 text-white p-2 rounded-lg"
                          onClick={() =>
                            onclickCommentaireButton(true, item.id)
                          }
                        >
                          Commentaire
                        </Button>
                        <Button
                          className="bg-color13 text-white p-2 rounded-lg"
                          onClick={() => onclickRelanceButton(true, item.id)}
                        >
                          Relance
                        </Button>
                        <Button
                          className="bg-color13 text-white p-2 rounded-lg"
                          onClick={() => onclickHistoriqueButton(true, item.id)}
                        >
                          Historique
                        </Button>
                        <Button
                          className="bg-color13 text-white p-2 rounded-lg"
                          onClick={() =>
                            onclickDateContactButton(true, item.id)
                          }
                        >
                          Date de Contact
                        </Button>
                        <Button
                          className="bg-color13 text-white p-2 rounded-lg"
                          onClick={() =>
                            onclickCodeCouleurButton(true, item.id)
                          }
                        >
                          Code Couleur
                        </Button>
                        <Button
                          className="bg-color13 text-white p-2 rounded-lg"
                          onClick={() =>
                            onclickPersonneChargeButton(true, item.id)
                          }
                        >
                          Personne en Charge
                        </Button>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
        {/* Modals */}
        <Modal show={showCommentModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter un Commentaire</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              rows="4"
              required
              value={commentaireInput}
              onChange={(e) => setCommentaireInput(e.target.value)}
              className="form-control"
              placeholder="Ajouter un commentaire..."
            ></textarea>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button
              variant="primary"
              onClick={(event) =>
                AddAllHistoriqueContent(user_idSelected, event)
              }
            >
              Enregistrer
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Relance */}
        <Modal show={showTaskModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter une Tâche de Relance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="date"
              value={DateRelanceInput}
              onChange={(e) => setDateRelanceInput(e.target.value)}
              className="form-control"
            />
            <textarea
              rows="4"
              className="form-control"
              required
              value={RelanceInput}
              onChange={(e) => setRelanceInput(e.target.value)}
              placeholder="Ajouter une tâche de relance..."
            ></textarea>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button
              variant="primary"
              onClick={(event) =>
                AddAllHistoriqueContent(user_idSelected, event)
              }
            >
              Enregistrer
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Historique*/}
        <Modal show={showHistoryModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Historique des Interactions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {ListHistoriqueSelected.map((i) => (
              <p key={i.id_historique}>
                {i.status} -
                <span className="font-semibold text-sm font-Poppins text-color7">
                  {i.status === "Relance" ? "(" + i.date_relance + ")" : ""}
                </span>{" "}
                {i.commentaire}
              </p>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Date de Contat */}
        <Modal show={showContactDateModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Date de Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="date"
              value={DateContactInput}
              onChange={(e) => setDateContactInput(e.target.value)}
              className="form-control"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button
              variant="primary"
              onClick={(event) =>
                AddAllHistoriqueContent(user_idSelected, event)
              }
            >
              Enregistrer
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Code Couleur*/}

        <Modal show={showColorCodeModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter un Code Couleur</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-row justify-between items-center">
              <p
                className={`border-[3px] font-Poppins  font-bold p-3  w-[100px] flex justify-center items-center text-color5 rounded-full cursor-pointer bg-color8 ${
                  CodeColorInput === "#6EBF48"
                    ? "border-color10"
                    : "border-color5"
                }`}
                onClick={() => changeEtatColorCode("#6EBF48")}
              >
                Vert
              </p>
              <p
                className={`border-[3px] font-Poppins  font-bold p-3  w-[100px] flex justify-center items-center text-color5 rounded-full cursor-pointer bg-color9 ${
                  CodeColorInput === "#FA5450"
                    ? "border-color10"
                    : "border-color5"
                }`}
                onClick={() => changeEtatColorCode("#FA5450")}
              >
                Rouge
              </p>
              <p
                className={`border-[3px] font-Poppins  font-bold p-3  w-[100px] flex justify-center items-center text-color5 rounded-full cursor-pointer bg-black ${
                  CodeColorInput === "#000000"
                    ? "border-color10"
                    : "border-color5"
                }`}
                onClick={() => changeEtatColorCode("#000000")}
              >
                Noir
              </p>
              <p
                className={`border-[3px] font-Poppins  font-bold p-3  w-[100px] flex justify-center items-center  text-color5 rounded-full cursor-pointer bg-color3 ${
                  CodeColorInput === "#F8BC00"
                    ? "border-color10"
                    : "border-color5"
                }`}
                onClick={() => changeEtatColorCode("#F8BC00")}
              >
                Orange
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button
              variant="primary"
              onClick={(event) =>
                AddAllHistoriqueContent(user_idSelected, event)
              }
            >
              Enregistrer
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Contact personne*/}

        <Modal show={showContactPersonModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter une Personne en Charge</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              value={PersonneChargeInput}
              onChange={(e) => setPersonneChargeInput(e.target.value)}
              className="form-control"
              placeholder="Nom de la personne"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button
              variant="primary"
              onClick={(event) =>
                AddAllHistoriqueContent(user_idSelected, event)
              }
            >
              Enregistrer
            </Button>
          </Modal.Footer>
        </Modal>
        {/* NewUserModal Component */}
        <NewUserModal
          showModalUser={showModalUser}
          handleClose={handleCloseModalUser}
          responseAfterSend={responseAfterSend}
        />

        <UpdateUserModal
          showUpdateModalUser={showUpdateModalUser}
          handleUpdateClose={handleUpdateCloseModalUser}
          userData={userDataSelected}
          responseAfterSend={responseAfterSend}
        />
        {loading && <WaitingDialog />}
      </div>
    </div>
  );
};

export default Home;
