import React, { useEffect } from "react";

const AlertModal = ({ show, message, isAvailable, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000); // Close after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  if (!show) return null;

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className={`relative p-4 w-full max-w-md max-h-full ${
          isAvailable ? "bg-color8" : "bg-color9"
        } text-white rounded-lg shadow-lg`}
      >
        <div className="p-4 text-center">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
