import React, { useEffect, useState } from "react";
import ButtonNavbar from "./ButtonNavbar";
import WaitingDialog from "../components/WaitingDialog";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { getNotifications } from "../services/ClientService";

const Navbar = () => {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      setLoading(true);
      Cookies.remove("authToken");
      navigate("/login");
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Failed to log out:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchNotifications = async () => {
    try {
      const data = await getNotifications();
      setNotifications(data);
      console.log("data notification", data);
    } catch (error) {
      console.error("Error fetching notifications:", error.message);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const isAuthenticated = Cookies.get("authToken") ? true : false;

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (event.target.closest(".notification-container") === null) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="h-15 px-1 w-full bg-black sm:px-5 md:px-10 lg:px-20 xl:px-20 2xl:px-20 3xl:px-20">
      {loading && <WaitingDialog />}

      <nav className="flex justify-between items-center container py-3">
        <a href="/">
          <h2 className="mr-5">
            <p className="font-Poppins font-bold text-3xl text-color7 hover:text-color11">
              IACADEMY
            </p>
          </h2>
        </a>
        <div className="flex flex-row justify-between items-center gap-10">
          <div className="relative notification-container">
            <button onClick={toggleDropdown} className="relative">
              <svg
                className="w-6 h-6 text-white hover:text-color3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-5-5.917V5a2 2 0 10-4 0v.083A6.002 6.002 0 004 11v3.159c0 .538-.214 1.055-.595 1.436L2 17h13zM13 21a2 2 0 01-4 0"
                ></path>
              </svg>
              {notifications.length > 0 && (
                <span className="absolute top-0 right-0 inline-block w-3 h-3 bg-red-500 rounded-full"></span>
              )}
            </button>

            {dropdownVisible && (
              <div className="absolute right-0 mt-2 w-80 bg-white shadow-lg rounded-md p-4 z-50">
                <h3 className="font-bold text-lg mb-2">
                  Notifications des relances
                </h3>
                <div className="max-h-80 overflow-y-scroll">
                  <ul>
                    {notifications.slice(0, 3).map((notif) => (
                      <li key={notif.id_historique} className="mb-2">
                        <p>
                          <strong>Nom:</strong>
                          <span className="font-Poppins font-bold text-sm text-color12">
                            {" "}
                            {notif.nomComplet}
                          </span>
                        </p>
                        <p>
                          <strong>E-mail:</strong>
                          <span className="font-Poppins font-bold text-sm text-color7 ">
                            {" "}
                            {notif.mail}
                          </span>
                        </p>
                        <p>
                          <strong>Relance Date:</strong>
                          <span className="font-Poppins font-bold text-sm text-color9">
                            {" "}
                            {notif.date_relance}
                          </span>
                        </p>
                        <hr className="my-2" />
                      </li>
                    ))}
                    {notifications.length > 3 && (
                      <li className="text-center text-gray-600">
                        <p>More notifications...</p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className="lg:flex flex-row gap-9 hidden items-center">
            {isAuthenticated && (
              <ButtonNavbar title="Se déconnecter" onClick={handleLogout} />
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
