import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Renewpwd } from "../services/LoginService"; // Adjust the import path as needed
import AlertModal from "../components/AlertModal"; // Import AlertModal
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import WaitingDialog from "../components/WaitingDialog"; // Import WaitingDialog component

const Forgetpwd = () => {
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false); // State for toggling old password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // State for toggling new password visibility
  const [showAlert, setShowAlert] = useState(false);
  const [messageDetailsPrice, setMessageDetailsPrice] = useState("");
  const [isAvailable, setIsAvailable] = useState(false); // true for success, false for error
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate that old password is not the same as new password
    if (oldPassword === newPassword) {
      setMessageDetailsPrice(
        "Le nouveau mot de passe ne peut pas être le même que l’ancien."
      );
      setIsAvailable(false);
      setShowAlert(true);
      return;
    }
    try {
      setLoading(true);
      const message = await Renewpwd(oldPassword, newPassword, email);
      setLoading(false);

      if (message === "Mot de passe modifié avec succès.") {
        setIsAvailable(true);
        setMessageDetailsPrice(message);
        setShowAlert(true);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        setIsAvailable(false);
        setMessageDetailsPrice(message);
        setShowAlert(true);
      }
    } catch (err) {
      setLoading(false);
      setMessageDetailsPrice(err.message);
      setIsAvailable(false);
      setShowAlert(true);
    }
  };

  // Function to toggle old password visibility
  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  // Function to toggle new password visibility
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Modifier Le Mot De Passe
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Adresse e-mail
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="exemple@mail.com"
            />
          </div>

          <div>
            <label
              htmlFor="Oldpassword"
              className="block text-sm font-medium text-gray-700"
            >
              Ancien Mot de passe
            </label>
            <div className="relative">
              <input
                type={showOldPassword ? "text" : "password"}
                id="Oldpassword"
                name="Oldpassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="********"
              />
              <button
                type="button"
                onClick={toggleOldPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                {showOldPassword ? (
                  <FaEyeSlash className="text-gray-500" />
                ) : (
                  <FaEye className="text-gray-500" />
                )}
              </button>
            </div>
          </div>

          <div>
            <label
              htmlFor="Newpassword"
              className="block text-sm font-medium text-gray-700"
            >
              Nouveau Mot de passe
            </label>
            <div className="relative">
              <input
                type={showNewPassword ? "text" : "password"}
                id="Newpassword"
                name="Newpassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="********"
              />
              <button
                type="button"
                onClick={toggleNewPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                {showNewPassword ? (
                  <FaEyeSlash className="text-gray-500" />
                ) : (
                  <FaEye className="text-gray-500" />
                )}
              </button>
            </div>
          </div>

          <div className="flex flex-col gap-1 items-center justify-between">
            <button
              type="submit"
              className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Valider
            </button>
            <Link
              to="/login"
              className="text-sm text-indigo-600 hover:text-indigo-700"
            >
              Se Connecter ?
            </Link>
          </div>
        </form>
      </div>

      {/* Render the AlertModal */}
      <AlertModal
        show={showAlert}
        message={messageDetailsPrice}
        isAvailable={isAvailable}
        onClose={() => setShowAlert(false)}
      />
      {loading && <WaitingDialog />}
    </div>
  );
};

export default Forgetpwd;
