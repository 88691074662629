import React from "react";

const ButtonNavbar = ({ title, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="rounded-xl cursor-pointer"
      style={{ background: "linear-gradient(to right, #F8BC00, #FFE924)" }}
      onMouseEnter={(e) =>
        (e.currentTarget.style.background =
          "linear-gradient(to left, #F8BC00, #FFE924)")
      }
      onMouseLeave={(e) =>
        (e.currentTarget.style.background =
          "linear-gradient(to right, #F8BC00, #FFE924)")
      }
    >
      <p className="px-9 py-1 text-center">{title}</p>
    </div>
  );
};

export default ButtonNavbar;
