// HistoriqueModel.jsx
class HistoriqueModel {
  constructor(data) {
    this.id_historique = data.id_historique;
    this.user_id = data.user_id;
    this.status = data.status; //soit Commentaire,Relance,Date de Contact, Personne en Charge
    this.commentaire = data.commentaire;
    this.date_relance = data.date_relance;
  }
}

export default HistoriqueModel;
